import { SPAM_SEARCH_INPUT_ALERT_MESSAGE } from 'constants/alertMessage'

/**
 * @desc 검색 창 입력값에 허용문자(한글,영문,숫자,일부 특수문자 ( ) [ ] % & , . ㈜ ㈔ ' / < >)외 특수문자가 있는지 확인
 * @param input 검색 창 입력값
 * @returns
 */

const hasSpecialChars = (input: string) => {
  return /[^\wㄱ-힣()[\]%&,.㈜㈔'/<> \n\r\t]|[\u4e00-\u9fff]/g.test(input)
}

/**
 * @desc 검색 창 입력값에 스팸성 키워드가 포함되어 있는지 확인 (서치 콘솔 3개월 내 검색 순위 키워드)
 * @param input 검색 창 입력값
 * @returns
 */

const PROHIBITED_SEARCH_KEYWORDS = [
  '킹콩티비',
  '마징가티비',
  '블루티비',
  '야동사이트',
  'bj야동',
  '야동코',
  '야동19',
  'av야동',
  '한국야동',
  '야동나라',
]

const hasSpamKeywords = (input: string) => {
  const inputString = input.replace(/\s+/g, '')

  const hasProhibitedKeyword = PROHIBITED_SEARCH_KEYWORDS.some((word) =>
    inputString.includes(word),
  )

  return hasProhibitedKeyword
}

/**
 * @desc 입력값에 특수문자, 스팸성 키워드가 있는지 확인
 * @param inputArray  입력한 키워드 배열
 * @param requestAlert  alert노출 유무 (서버사이드에서 실행 시에 false 전달)
 * @returns 특수문자, 스팸성 키워드가 있는지 유무
 */

export const isSpamSearchInput = (
  inputArray: string[],
  requestAlert = true,
) => {
  const hasSpamInput = inputArray.some(
    (input) => hasSpecialChars(input) || hasSpamKeywords(input),
  )

  if (hasSpamInput) {
    if (requestAlert) {
      alert(SPAM_SEARCH_INPUT_ALERT_MESSAGE)
    }
    return true
  }
  return false
}
